// ProtectedRoute.js
import React from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  if (token) {
    sessionStorage.setItem('authToken', token);
    // navigate('/');
  }
  const isAuthenticated = !!sessionStorage.getItem('authToken');
  return isAuthenticated ? <Component /> : <Navigate to="/welcome" />;
};

export default ProtectedRoute;
