import React from 'react'
import { useState } from 'react';
import '../CSS/Services.css'
import {useLocation, useNavigate} from "react-router-dom";
import Footer from '../elements/Footer.js';
import Header2 from "../elements/Header2";

// import axios from 'axios';
export default function Services() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLoginRedirect = () => {
    // window.location.href = "/login";
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      sessionStorage.setItem('authToken', token);
      navigate('/');
    } else {
      window.location.href = `https://auth.jiricech2059.com/login?from=${window.location.origin}`;
    }
  }
  return (
    <div className="mainContent" id="mainContent">
      <Header2 />
      <div className="servicesContent">
        {/*<h1 className="welcomeTitle">Welcome to the Image Finder, powered by Jiricech2059 Services</h1>*/}
        <h1 className="servicesTitle">These are our current services</h1>
        <div className="servicesBox">
          <div className="service-imageFinder">
            {/*<img src="https://jiricech2059.com/images/imageFinder.png" alt="Image Finder" className="serviceImage"/>*/}
            <img src={process.env.PUBLIC_URL + '/services/imageFinder.png'} alt="Image Finder" className="serviceImage"></img>
            <div className="servicesTexts">
              <p className="servicesTextsTitle">Image Finder</p>
              <p className="servicesTextsDescription">With our advanced Image Finder, you can quickly <span
                style={{display: 'block'}}>and easily find the images you need!</span> Whether it's nature, oceans,
                cars, life scenes, or flora, <span style={{display: 'block'}}>our intuitive search and categories make it simple.</span>
              </p>
            </div>
            <div className="servicesTextsBenefits">
              <p className="servicesTextsBenefitsTitle">Key Benefits:</p>
              <ul className="servicesTextsBenefitsList">
                <li className="servicesTextsBenefitsListItem">Quick Search: Find images instantly with keywords</li>
                <li className="servicesTextsBenefitsListItem">Categories: Browse by specific categories</li>
                <li className="servicesTextsBenefitsListItem">High Quality: Access high-resolution images</li>
                <li className="servicesTextsBenefitsListItem">User-Friendly: Easy and efficient to use</li>
              </ul>
            </div>
          </div>
          <div className="services-more">
            <hr className="line"/>
            <span className="services-more-text">MORE COMING SOON</span>
            {/*<span className="services-more-text2">We are working on it!</span>*/}
            <hr className="line"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

