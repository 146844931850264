import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/Header.css'
export default function Header() {
  let navigate = useNavigate();


  const handleTitle = () => {
    navigate('/')
  }
  return (
    <header id="mainHeader">
      <div className="mainHeaderContent" id="mainHeaderContent">
        <div className="titleContainer" id="titleContainer">
          <p className="serviceTitle" onClick={handleTitle}>Jiricech2059 services | <span className="headerNavigationSpan"><span className="headerNavigationSpanItem"><a>Services</a></span><span className="headerNavigationSpanItem"><a>Support</a></span><span className="headerNavigationSpanItem"><a>Feedback</a></span></span></p>
        </div>
        {/*<nav className="headerNavigation">*/}
        {/*<ul className="headerNavigationContent">*/}
        {/*    <li className="headerNavigationItem">*/}
        {/*      <a>Services</a>*/}
        {/*    </li>*/}
        {/*    <li className="headerNavigationItem">*/}
        {/*      <a>Support</a>*/}
        {/*    </li>*/}
        {/*    <li className="headerNavigationItem">*/}
        {/*      <a>Feedback</a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</nav>*/}
        <div className="titleContainer2" id="titleContainer2">
          <img id="userIcon" src={process.env.PUBLIC_URL + '/user-icon.png'} alt="Profile"></img>
        </div>
        {/*<div className="nav-div" id="nav-div">*/}
        {/*    <div id="userContent">*/}
        {/*        <nav className="header-nav" id="header-nav">*/}
        {/*            <li className="nav-item" id="nav-item1">*/}
        {/*                <a href="/" aria-current="page" role="link" className="dark-theme">Home</a>*/}
        {/*            </li>*/}
        {/*            <li className="nav-item" id="nav-item2">*/}
        {/*                <a href="/services" aria-current="page" role="link" className="dark-theme">Services</a>*/}
        {/*            </li>*/}
        {/*            <li className="nav-item" id="nav-item3">*/}
        {/*                <a href="/projects" aria-current="page" role="link" className="dark-theme">Projects</a>*/}
        {/*            </li>*/}
        {/*        </nav>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    </header>
  )
}
