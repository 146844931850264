import React from 'react'
import { useState } from 'react';
import '../CSS/Welcome.css'
import {useLocation, useNavigate} from "react-router-dom";
import Footer from '../elements/Footer.js';
import Header2 from "../elements/Header2";
// import axios from 'axios';
export default function Welcome() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLoginRedirect = () => {
    // window.location.href = "/login";
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      sessionStorage.setItem('authToken', token);
      navigate('/');
    } else {
      window.location.href = `https://auth.jiricech2059.com/login?from=${window.location.origin}`;
    }
  }
  return (
    <div className="mainContent" id="mainContent">
      <Header2 />
      <div className="welcomeContent">
        {/*<h1 className="welcomeTitle">Welcome to the Image Finder, powered by Jiricech2059 Services</h1>*/}
        <h1 className="welcomeTitle">Welcome to the <span className="welcomeTitleSpan">Jiricech2059 services</span> </h1>
        {/*<p className="welcomeSubTitle">Search for images using the search bar above</p>*/}
        <p className="welcomeSubtitle2">The place that makes your everyday life easier! <span className="welcomeSubtitleSpan2">Lots of useful & modern apps in one place</span></p>
        {/*<p className="welcomeSubTitle">Login to use our apps & services</p>*/}
        <button className="welcomeLoginButton" onClick={handleLoginRedirect}>Login</button>
      </div>
      <Footer/>
    </div>
  )
}

