import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useHistory } from "react-router-dom";
import Footer from '../elements/Footer.js';
import '../CSS/Login.css'
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      sessionStorage.setItem('authToken', token);
      navigate('/');
    } else {
      window.location.href = `https://auth.jiricech2059.com?from=${window.location.origin}`;
    }
  }, [navigate, location]);

  return <div>Redirecting...</div>;
}

