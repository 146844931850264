import React from 'react'
import { useState } from 'react';
// import axios from 'axios';
import Header from '../elements/Header.js';
import Footer from '../elements/Footer.js';
import '../CSS/Home.css'
// import SearchSVG from "../magnifying-glass-solid.svg";
// import ImageList from './ImageList';
export default function Home() {
  const [Finder, setFinder] = useState('');
  const [images, setImages] = useState([]);

  const handleSearch = async (term) => {
    console.log("search")
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearch(Finder);
  };

  const findCategory = (term) => {
    handleSearch(term)
  }
  return (
    <div className="mainContent" id="mainContent">
      <Header />
      <div className="mainFinder">
        {/*<p>TEST</p>*/}
        <div className="mainFinderNav">
          <div className="mainFinderNavSearch">
            <form onSubmit={handleSubmit}>
              <input
                className="mainFinderInput"
                type="text"
                onChange={(e) => setFinder(e.target.value)}
                placeholder="Search"
              />
              {/*<button className="mainFinderButton" onClick={handleSubmit}>*/}
              <button className="mainFinderButton" type="submit">
                {/*<img src={SearchSVG} alt="Search" className="mainFinderButtonIcon"/>*/}
              </button>
            </form>
          </div>
          <div className="mainFinderNavCategories">
            <div className="category" onClick={() => findCategory("nature")}>
              <p className="categoryText">Nature</p>
            </div>
            <div className="category" onClick={() => findCategory("ocean")}>
              <p className="categoryText">Ocean</p>
            </div>
            <div className="category" onClick={() => findCategory("car")}>
              <p className="categoryText">Car</p>
            </div>
            <div className="category" onClick={() => findCategory("life")}>
              <p className="categoryText">Life</p>
            </div>
            <div className="category" onClick={() => findCategory("flora")}>
              <p className="categoryText">Flora</p>
            </div>
          </div>
        </div>
        <div className="mainFinderContent">
          <p className="noImagesText" id="noImagesText">Try search something with the search bar or choose category</p>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

