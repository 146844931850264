import React from 'react'
import './App.css';
import Home from './components/pages/Home';
import Welcome from './components/pages/Welcome';
import Login from './components/pages/Login';
import ProtectedRoute from './components/pages/ProtectedRoute';
import Services from './components/pages/Services';
import Support from './components/pages/Support';
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <>
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute element={Home} />}
          />
          <Route
            path='/welcome'
            element={
              <Welcome />}
          />
          <Route
            path='/services'
            element={
              <Services />}
          />
          <Route
            path='/support'
            element={
              <Support />}
          />
          <Route
            path='/login'
            element={
              <Login />}
          />
        </Routes>
      </>
    </div>
  );
}

export default App;
