import React from 'react'
import { useState } from 'react';
import '../CSS/Support.css'
import {useLocation, useNavigate} from "react-router-dom";
import Footer from '../elements/Footer.js';
import Header2 from "../elements/Header2";

// import axios from 'axios';
export default function Services() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLoginRedirect = () => {
    // window.location.href = "/login";
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      sessionStorage.setItem('authToken', token);
      navigate('/');
    } else {
      window.location.href = `https://auth.jiricech2059.com/login?from=${window.location.origin}`;
    }
  }
  return (
    <div className="mainContent" id="mainContent">
      <Header2 />
      <div className="supportContent">
        {/*<h1 className="welcomeTitle">Welcome to the Image Finder, powered by Jiricech2059 Services</h1>*/}
        <h1 className="supportTitle">Working on support system, please be patient. Soon it will be there</h1>
      </div>
      <Footer/>
    </div>
  )
}

